export default {
	pageSize: 20,

	apiRoot: process.env.REACT_APP_API_ROOT,
	mediaUrl: '//geymd.arnastofnun.is/ismus/',
	lbsImageUrl: '//myndir.handrit.is/source/',
	neo4jApi: process.env.REACT_APP_NEO_API,

	musicXmlUrl: '/media/musicxml/',

	siteRoot: process.env.REACT_APP_SITE_ROOT,

	siteTitle: 'Ísmús',

	localLibraryName: 'ismus',

	gaTrackingId: 'G-81TJ32Z61L'
}
